<template>
  <div class="alita-home">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane
        label="待审核"
        name="two"
        v-if="checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_SELECT','SrPortalStatusDetail_CREATE'])"
      >
        <tableView
          :data-status="2"
          :re-fresh="reFresh2"
        />
      </el-tab-pane>
      <el-tab-pane
        label="待搭建"
        name="three"
        v-if="checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_SELECT','SrPortalStatusDetail_CREATE','SrPortalStatusDetail_COPY'])"
      >
        <tableView
          :data-status="3"
          :re-fresh="reFresh3"
        />
      </el-tab-pane>
      <el-tab-pane
        label="建站完成"
        name="four"
        v-if="checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_SELECT','SrPortalStatusDetail_CREATE'])"
      >
        <tableView
          :data-status="4"
          :re-fresh="reFresh4"
        />
      </el-tab-pane>
      <el-tab-pane
        label="审核拒绝"
        name="five"
        v-if="checkPermission(['ADMIN','SrPortalStatusDetail_ALL','SrPortalStatusDetail_SELECT','SrPortalStatusDetail_CREATE'])"
      >
        <tableView
          :data-status="5"
          :re-fresh="reFresh5"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import tableView from '@/components/alitatech/apply/table'
  import checkPermission from '@/utils/permission'

  export default {
    name: "apply",
    components: {
      tableView
    },
    data() {
      return {
        activeName: 'two',
        reFresh2: false,
        reFresh3: false,
        reFresh4: false,
        reFresh5: false,
      }
    },
    methods: {
      checkPermission,
      handleClick(tab, event) {
        switch (tab.name) {
          case 'two':
            this.reFresh2 = !this.reFresh2;
            break;
          case 'three':
            this.reFresh3 = !this.reFresh3;
            break;
          case 'four':
            this.reFresh4 = !this.reFresh4;
            break;
          case 'five':
            this.reFresh5 = !this.reFresh5;
            break;
        }
      }
    }
  }
</script>

<style scoped>
.title {
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
}
</style>
